export const environment = {
  production: true,
  apiurl: 'https://soap2.omnicms3.nl:9004/api/v1/',
  mock: false,
  config: {
    product: "Mijn Tallandpas",
    customer_fullname: "Talland College",
    customer_shortname: "Talland",
    url: "https://mijnhorizonpas.mijnomnipas.nl/site/assets/docs/horizonstudentenpas.pdf",
    phone: "088 804 6000",
    email: "servicepunt@talland.nl",
    MFAemail: false,
    MFAsms: false,
    logintype : "surf",
    autologin: false,
    username: "",
    password: "",
    samlConfig: {},
    surfConfig: { issuer: "https://connect.surfconext.nl"
                , clientId : "horizonpas.mijnomnipas.nl"
                , redirectUri: "https://mijnhorizonpas.mijnomnipas.nl/site/surflogin"
                , silentRefreshRedirectUri: "https://mijnhorizonpas.mijnomnipas.nl/site/silent-refresh.html"
                , logoutUrl: "https://engine.surfconext.nl/logout"
                , scope: "openid profile email"
                , loginItem: "uids"
                , oidc: true
                , skipPage: true
                , secret: "mgMKjB5ZskodTESXnoya"
                },
    adfsConfig: {},
    ingenico: {
      url: "https://secure.ogone.com/ncol/prod/orderstandard.asp",
      orderid: "",
      pspid: "HorizonCollegePROD",
      sha_in_key: "4JCt*lD1kM@X6fjuY[U",
      sha_out_key: "m#SzwH0l6s4ruiPVV{)",
      currency: "EUR",
      bgcolor: "#F3F3F3",
      txtcolor: "#004C93",
      tblbgcolor: "#FFFFFF",
      tbltxtcolor: "#666666",
      buttonbgcolor: "#004C93",
      buttontxtcolor: "#FFFFFF",
      fonttype: "Verdana",
      pm: "iDEAL",
      homeurl: "https://mijnhorizonpas.mijnomnipas.nl/site/payment",
      accepturl: "https://mijnhorizonpas.mijnomnipas.nl/site/payment/success",
      declineurl: "https://mijnhorizonpas.mijnomnipas.nl/site/payment/decline",
      exceptionurl: "https://mijnhorizonpas.mijnomnipas.nl/site/payment/fail",
      cancelurl: "https://mijnhorizonpas.mijnomnipas.nl/site/payment/cancel"
    },
    cardprices: { "Student": 1000, "Employee": 0, "External employee": 0, "default": 1000 },
    languages: [
      {
        id: 1,
        LanguageCode: "nl",
        LanguageName: "Nederlands",
        Card: "Tallandpas"
      }
    ],
    nameoncardformats: [
      { id: 1, format: "<<Initials>> <<MiddleName>> <<LastName>>" },
      { id: 2, format: "<<FirstName>> <<MiddleName>> <<LastName>>" },
      { id: 3, format: "<<LastName>>, <<FirstName>> <<MiddleName>>" }
    ],
    savephotoonserver: false,
    changepublishpermission: false,
    chargingcard: false,
    digitalcard: false,
    uploadphoto: true,
    webcam: true,
    licenseplate: false,
    nameoncard: false,
    cardpreview: true,
    cardaslink: true,
    cardresult: false,
    cardactivate: false,
    cardblock: true,
    carddeblock: false,
    cardrequest: true,
    logoutbutton: false,
    requestnewpassword: false,
    publishPermission: false,
    scrollButtons: false,
    breadcrumbs: false,
    versioninfooter: false,
    photoResizeToWidth: 480,
    photoMiniatureWidth: 240,
    togglefont: false,
    photorequirements: false,
    firstnameonwelcome: true,
    photoAgreedNotOnFirstPage: true,
    deliveryaddress: false,
    langimage : false,
    maintainance: false,
    timeout: 180,
    horizon: true
  },
  ssl: true
};
